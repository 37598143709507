import { HttpServiceV1 } from '@data/common/services'

import { ExceptionService } from '@domain/common/services'
import { InternalCode } from '@domain/common/enums'

import {
  BinanceOrderSide,
  transformSideFromBinance,
  transformStatusFromBinance,
  transformTypeFromBinance
} from '@data/stocks/binance'

import type { IPairDTO } from '@domain/stocks/pair'

import type { IDealResponse, IDealResponseErrors, IDealServerError } from './interface'

import type {
  IActiveOrderUpdateRequest,
  IClosedDealDTO,
  IClosedDealPayload,
  IDealDTO,
  IDealErrorsIds,
  IDealErrorsUpdate,
  IDealRepository,
  IDealsEntityRequest,
  IDealsUpdateRequest,
  IGetDealListPort
} from '@domain/stocks/interfaces/deal'

import type { IHttpError } from '@data/common/interfaces'

/* eslint-disable @typescript-eslint/no-magic-numbers */
class BinanceDealRepository implements IDealRepository {

  private _provider: string

  constructor (provider: string) {
    this._provider = provider
  }

  public async getDeals (port: IGetDealListPort): Promise<IDealDTO[]> {
    return HttpServiceV1.get<IDealResponse[] | null>(`${this._provider}/active-deals`)
      .then((response) => {
        if (response === null) return []

        return this._transformDealList(response, port.list)
      })
      .catch(() => {
        throw ExceptionService.new({
          status: {
            code: InternalCode.SERVER_ERROR,
            message: 'Server return bad request'
          }
        })
      })
  }

  public async getClosedDeals (payload: IClosedDealPayload): Promise<IClosedDealDTO[]> {
    return HttpServiceV1.get<IDealResponse[] | null>(`${this._provider}/archive-deals`, { params: payload })
      .then((response) => {
        if (response === null) return []

        return this._transformClosedDeal(response)
      })
      .catch(() => {
        throw ExceptionService.new({
          status: {
            code: InternalCode.SERVER_ERROR,
            message: 'Server return bad request'
          }
        })
      })
  }

  public async cancelDeal (port: IDealsEntityRequest): Promise<boolean> {
    return HttpServiceV1.post(`${this._provider}/cancel-deal`, { body: port })
      .then(() => true)
      .catch((error: IHttpError<IDealResponseErrors | IDealServerError>) => {
        throw this._throwErrorIds(error)
      })
  }

  public async panicDeal (port: IDealsEntityRequest): Promise<boolean> {
    return HttpServiceV1.post(`${this._provider}/panic`, { body: port })
      .then(() => true)
      .catch((error: IHttpError<IDealResponseErrors | IDealServerError>) => {
        throw this._throwErrorIds(error)
      })
  }

  public async divisionDeal (port: IDealsEntityRequest): Promise<boolean> {
    return HttpServiceV1.post<IDealResponse[]>(`${this._provider}/division`, { body: port })
      .then(() => true)
      .catch((error: IHttpError<IDealResponseErrors | IDealServerError>) => {
        throw this._throwErrorIds(error)
      })
  }

  public async unionDeal (port: IDealsEntityRequest): Promise<boolean> {
    return HttpServiceV1.post<IDealResponse>(`${this._provider}/union`, { body: port })
      .then(() => {
        return true
      })
      .catch((error: IHttpError<IDealResponseErrors | IDealServerError>) => {
        throw this._throwErrorIds(error)
      })
  }

  public async updateDeal (port: IDealsUpdateRequest): Promise<boolean> {
    return HttpServiceV1.post(`${this._provider}/update-deal`, { body: port })
      .then(() => true)
      .catch((error: IHttpError<IDealResponseErrors | IDealServerError>) => {
        throw this._throwErrorUpdate(error)
      })
  }

  public async updateActiveOrderSettings (port: IActiveOrderUpdateRequest): Promise<boolean> {
    return HttpServiceV1.post(`${this._provider}/append-trade-manage`, { body: port })
      .then(() => true)
      .catch((error: IHttpError<IDealResponseErrors | IDealServerError>) => {
        throw this._throwErrorUpdate(error)
      })
  }

  private _transformClosedDeal (response: IDealResponse[]): IClosedDealDTO[] {
    return response.map((item) => {
      const stopLossOrder = {
        price: item.slOrderItem.price,
        date: item.slOrderItem.updateAt * 1000,
        status: transformStatusFromBinance(item.slOrderItem.status)
      }

      const takeProfitOrder = {
        price: item.tpOrderItem.price,
        date: item.tpOrderItem.updateAt * 1000,
        status: transformStatusFromBinance(item.tpOrderItem.status)
      }

      return {
        id: item.deal.id,
        symbol: item.deal.symbol,
        side: transformSideFromBinance(item.deal.side),
        status: item.deal.status,
        price: item.deal.price,
        profitUSDT: item.deal.usdtProfit,
        createdAt: item.deal.createAt * 1000,
        stopLossOrder: stopLossOrder,
        takeProfitOrder: takeProfitOrder
      }
    })
  }

  private _transformDealList (response: IDealResponse[], list: IPairDTO[]): IDealDTO[] {
    const deals: IDealDTO[] = []

    response.forEach((item) => {
      const dealPrice = Number(item.deal.price)
      const takeProfitPriceDifference = dealPrice * Number(item.deal.tp) / 100
      const stopLossPriceDifference = dealPrice * Number(item.deal.sl) / 100

      let takeProfitPrice = item.tpOrderItem.price
      let stopLossPrice = item.slOrderItem.price

      if (Number(takeProfitPrice) === 0) {
        takeProfitPrice = item.deal.side === BinanceOrderSide.SELL
          ? (dealPrice + takeProfitPriceDifference).toString()
          : (dealPrice - takeProfitPriceDifference).toString()
      }

      if (Number(stopLossPrice) === 0) {
        stopLossPrice = item.deal.side === BinanceOrderSide.SELL
          ? (dealPrice - stopLossPriceDifference).toString()
          : (dealPrice + stopLossPriceDifference).toString()
      }

      const masterOrder = {
        id: item.masterOrderItem.id,
        side: transformSideFromBinance(item.masterOrderItem.side),
        type: transformTypeFromBinance(item.masterOrderItem.type),
        status: transformStatusFromBinance(item.masterOrderItem.status),
        price: item.masterOrderItem.price,
        amount: item.masterOrderItem.qty,
        executedAmount: item.masterOrderItem.execQty,
        date: item.masterOrderItem.createAt * 1000,
        updatedTime: item.masterOrderItem.updateAt * 1000,
        commission: {
          percent: item.masterOrderItem.comPercent,
          amount: item.masterOrderItem.commission,
          asset: item.masterOrderItem.comTicker
        }
      }

      const takeProfitOrder = {
        id: item.tpOrderItem.id,
        side: transformSideFromBinance(item.tpOrderItem.side),
        type: transformTypeFromBinance(item.tpOrderItem.type),
        status: transformStatusFromBinance(item.tpOrderItem.status),
        amount: item.tpOrderItem.qty,
        executedAmount: item.tpOrderItem.execQty,
        price: takeProfitPrice,
        isInclude: item.deal.includeTp,
        percent: item.deal.tp,
        completePercent: (Number(item.tpOrderItem.execQty) / Number(item.tpOrderItem.qty) * 100).toString(),
        date: item.tpOrderItem.createAt * 1000,
        updatedTime: item.tpOrderItem.updateAt * 1000
      }

      const stopLossOrder = {
        id: item.slOrderItem.id,
        side: transformSideFromBinance(item.slOrderItem.side),
        type: transformTypeFromBinance(item.slOrderItem.type),
        status: transformStatusFromBinance(item.slOrderItem.status),
        amount: item.slOrderItem.qty,
        executedAmount: item.slOrderItem.execQty,
        price: stopLossPrice,
        isInclude: item.deal.includeSl,
        percent: item.deal.sl,
        completePercent: (Number(item.slOrderItem.execQty) / Number(item.slOrderItem.qty) * 100).toString(),
        date: item.slOrderItem.createAt * 1000,
        updatedTime: item.slOrderItem.updateAt * 1000
      }

      const pair = list.find((_pair) => _pair.ticker.symbol === item.deal.symbol)

      if (pair) {
        deals.push({
          id: item.deal.id,
          pair: pair,
          side: transformSideFromBinance(item.deal.side),
          status: item.deal.status,
          amount: item.deal.qty,
          price: item.deal.price,
          openUsdtPrice: item.deal.usdtOpenPrice,
          unionVolume: item.deal.unionVolume,
          unionCommission: item.deal.unionCommission,
          createdAt: item.deal.createAt * 1000,
          updatedAt: item.deal.updateAt * 1000,
          masterOrder: masterOrder,
          takeProfitOrder: takeProfitOrder,
          stopLossOrder: stopLossOrder
        })
      }
    })

    return deals
  }

  // eslint-disable-next-line max-len
  private _throwErrorIds (error: IHttpError<(IDealResponseErrors | IDealServerError)>): ExceptionService<IDealErrorsIds> {
    const code = error.errors === 'server_error' ? InternalCode.SERVER_ERROR : +error.errors.code
    const message = 'Backand return errors'

    return ExceptionService.new({
      status: {
        code,
        message
      },
      data: {
        logical: error.errors === 'server_error'
          ? InternalCode.SERVER_ERROR
          : +error.errors.code
      }
    })
  }

  // eslint-disable-next-line max-len
  private _throwErrorUpdate (error: IHttpError<IDealResponseErrors | IDealServerError>): ExceptionService<IDealErrorsUpdate> {
    const code = error.errors === 'server_error' ? InternalCode.SERVER_ERROR : +error.errors.code
    const message = 'Backand return errors'

    return ExceptionService.new({
      status: {
        code,
        message
      },
      data: {
        logical: error.errors === 'server_error'
          ? InternalCode.SERVER_ERROR
          : +error.errors.code
      }
    })
  }

}

export { BinanceDealRepository }
