import { initOrderSocket } from '@data/socket/stocks/order'

import { DealsSocket } from '@data/socket/stocks/binance/deals'

import { DealsStatus } from '@domain/stocks/enums'
import { OrderStatus, OrderType, OrderSource } from '@domain/stocks/order'

import type { IUserEntity } from '@domain/user/interfaces'

import type { IOrderSocket } from '@data/socket/stocks/order'

import type { IDealsPairObserverPayload } from '@app/pages/trade/trade-controller/observers'

import type { IClosedDealDTO, IDealDTO } from '@domain/stocks/interfaces/deal'
import type { IOrderDTO } from '@domain/stocks/order'
import type { IPairDTO } from '@domain/stocks/pair'
import type { IHistoryOrderDTO } from '@domain/stocks/account-trade-history'
import type { IDealsMessage } from '@data/socket/stocks/binance/deals/interfaces'

import type { Stocks } from '@domain/common/enums'

interface IDealEventPayload {
  message: IDealsMessage
  takeProfitOrder: IOrderDTO
  stopLossOrder: IOrderDTO
}

interface ISyncManagerEvents {
  onUpdateOrderList (value: IOrderDTO[]): void
  onUpdateClosedOrderList (value: IHistoryOrderDTO[]): void
  onUpdateDealList (value: IDealDTO[]): void
  onUpdateClosedDealList (value: IClosedDealDTO[]): void
  onNotifyDealsPair (value: IDealsPairObserverPayload): void
}

/* eslint-disable @typescript-eslint/no-magic-numbers */
class OrderDealSyncManager {

  private _orderSocket: IOrderSocket | null

  private _dealSocket: DealsSocket | null

  private _orderList: IOrderDTO[]

  private _closedOrderList: IHistoryOrderDTO[]

  private _dealList: IDealDTO[]

  private _closedDealList: IClosedDealDTO[]

  private _events: ISyncManagerEvents | null

  constructor () {
    this._orderSocket = null
    this._dealSocket = null

    this._orderList = []
    this._closedOrderList = []
    this._dealList = []
    this._closedDealList = []

    this._events = null
  }

  public subscribe (stock: Stocks, user: IUserEntity, list: IPairDTO[]): void {
    this._orderSocket = initOrderSocket({ stock, list })
    this._dealSocket = new DealsSocket(list)

    this._orderSocket.subscribe(user.id)
    this._dealSocket.subscribe(stock, user.id)

    this._orderSocket.onMessage(this._onOrderMessage.bind(this))
    this._dealSocket.onMessage(this._onDealMessage.bind(this))
  }

  public unsubscribe (): void {
    this._orderSocket?.unsubscribe()
    this._dealSocket?.unsubscribe()
  }

  public setEvents (events: ISyncManagerEvents): void {
    this._events = events
  }

  public setOrderList (value: IOrderDTO[]): void {
    this._orderList = value
  }

  public setClosedOrderList (value: IHistoryOrderDTO[]): void {
    this._closedOrderList = value
  }

  public setDealList (value: IDealDTO[]): void {
    this._dealList = value
  }

  public setClosedDealList (value: IClosedDealDTO[]): void {
    this._closedDealList = value
  }

  private _onOrderMessage (value: IOrderDTO): void {
    if (value.status === OrderStatus.NEW) this._onActivateOrder(value)
    if (value.status === OrderStatus.TRADE) this._onUpdateOrder(value)
    if (value.status === OrderStatus.COMPLETE) this._onCompleteOrder(value)
    if (value.status === OrderStatus.CANCELED) this._onCancelOrder(value)

    if (this._events) {
      this._events.onUpdateOrderList(this._orderList)
      this._events.onUpdateClosedOrderList(this._closedOrderList)
    }
  }

  private _onActivateOrder (value: IOrderDTO): void {
    if (value.type === OrderType.MARKET && value.source === OrderSource.ORIGIN) return

    this._orderList.push(value)
  }

  private _onUpdateOrder (value: IOrderDTO): void {
    this._orderList = this._orderList.map((item) => {
      return item.id === value.id ? value : item
    })
  }

  private _onCompleteOrder (value: IOrderDTO): void {
    this._deleteOrder(value.id)
  }

  private _onCancelOrder (value: IOrderDTO): void {
    this._closedOrderList.push({
      id: value.id,
      symbol: value.pair.ticker.symbol,
      status: value.status,
      source: value.source,
      price: Number(value.price),
      closeTime: value.updatedDate,
      createTime: value.createdDate
    })

    this._deleteOrder(value.id)
  }

  private _deleteOrder (id: string): void {
    this._orderList = this._orderList.filter((item) => item.id !== id)
  }

  private _onDealMessage (message: IDealsMessage): void {
    const takeProfitOrder = this._transformSocketOrder(message, 'takeProfitOrder')
    const stopLossOrder = this._transformSocketOrder(message, 'stopLossOrder')

    if (message.event === 'activated') {
      const isExistPair = this._dealList.some((item) => item.pair === message.deal.pair)

      if (!isExistPair && this._events) {
        this._events.onNotifyDealsPair({ action: 'add', pair: message.deal.pair })
      }
    }

    if (message.event === 'activated') this._onActivateDeal({ message, takeProfitOrder, stopLossOrder })
    if (message.event === 'updated') this._onUpdateDeal({ message, takeProfitOrder, stopLossOrder })
    if (message.event === 'filled') this._onCompleteDeal({ message, takeProfitOrder, stopLossOrder })
    if (message.event === 'canceled') this._onCancelDeal({ message, takeProfitOrder, stopLossOrder })

    if (['filled', 'canceled'].includes(message.event)) {
      const isExistPair = this._dealList.some((item) => item.pair === message.deal.pair)

      if (!isExistPair && this._events) {
        this._events.onNotifyDealsPair({ action: 'delete', pair: message.deal.pair })
      }
    }

    if (this._events) {
      this._events.onUpdateOrderList(this._orderList)
      this._events.onUpdateClosedOrderList(this._closedOrderList)
      this._events.onUpdateDealList(this._dealList)
      this._events.onUpdateClosedDealList(this._closedDealList)
    }
  }

  private _onActivateDeal (payload: IDealEventPayload): void {
    const { message, takeProfitOrder, stopLossOrder } = payload

    this._dealList.push(message.deal)
    this._orderList = this._orderList.filter((item) => item.id !== takeProfitOrder.id)

    if (Number(takeProfitOrder.id) !== 0) this._onActivateOrder(takeProfitOrder)
    if (Number(stopLossOrder.id) !== 0) this._onActivateOrder(stopLossOrder)
  }

  private _onUpdateDeal (payload: IDealEventPayload): void {
    const { message, takeProfitOrder, stopLossOrder } = payload

    this._dealList = this._dealList.map((item) => {
      if (item.id === message.deal.id) {
        if (Number(takeProfitOrder.id) !== 0) {
          if (item.takeProfitOrder.isInclude === takeProfitOrder.takeProfit.isInclude) {
            this._orderList = this._orderList.map((order) => {
              return order.id === item.takeProfitOrder.id.toString()
                ? { ...takeProfitOrder, status: OrderStatus.TRADE }
                : order
            })
          } else {
            takeProfitOrder.takeProfit.isInclude
              ? this._onActivateOrder({ ...takeProfitOrder, status: OrderStatus.NEW })
              : this._onCancelOrder({ ...takeProfitOrder, status: OrderStatus.CANCELED })
          }
        }

        if (Number(stopLossOrder.id) !== 0) {
          if (item.stopLossOrder.isInclude === stopLossOrder.stopLoss.isInclude) {
            this._orderList = this._orderList.map((order) => {
              return order.id === item.stopLossOrder.id.toString()
                ? { ...stopLossOrder, status: OrderStatus.TRADE }
                : order
            })
          } else {
            stopLossOrder.stopLoss.isInclude
              ? this._onActivateOrder({ ...stopLossOrder, status: OrderStatus.NEW })
              : this._onCancelOrder({ ...stopLossOrder, status: OrderStatus.CANCELED })
          }
        }

        item = message.deal
      }

      return item
    })
  }

  private _onCompleteDeal (payload: IDealEventPayload): void {
    this._deleteDeal(payload.message)

    const isDuplicate = this._closedDealList.filter((item) => item.id === payload.message.deal.id).length > 0

    if (!isDuplicate) {
      this._closedDealList.push({
        id: payload.message.deal.id,
        symbol: payload.message.deal.pair.ticker.symbol,
        side: payload.message.deal.side,
        price: payload.message.deal.price,
        profitUSDT: payload.message.deal.profitUSDT,
        createdAt: payload.message.deal.createdAt,
        status: DealsStatus.COMPLETED,
        stopLossOrder: {
          price: payload.message.deal.stopLossOrder.price,
          date: payload.message.deal.stopLossOrder.updatedTime ?? 0,
          status: payload.message.deal.stopLossOrder.status
        },
        takeProfitOrder: {
          price: payload.message.deal.takeProfitOrder.price,
          date: payload.message.deal.takeProfitOrder.updatedTime ?? 0,
          status: payload.message.deal.takeProfitOrder.status
        }
      })
    }

    this._orderList = this._orderList.filter((item) => item.dealId !== payload.message.deal.id)
  }

  private _onCancelDeal (payload: IDealEventPayload): void {
    this._deleteDeal(payload.message)

    this._closedDealList.push({
      id: payload.message.deal.id,
      symbol: payload.message.deal.pair.ticker.symbol,
      side: payload.message.deal.side,
      price: payload.message.deal.price,
      profitUSDT: payload.message.deal.profitUSDT,
      createdAt: payload.message.deal.createdAt,
      status: DealsStatus.CANCELED,
      stopLossOrder: {
        price: payload.message.deal.stopLossOrder.price,
        date: payload.message.deal.stopLossOrder.updatedTime ?? 0,
        status: payload.message.deal.stopLossOrder.status
      },
      takeProfitOrder: {
        price: payload.message.deal.takeProfitOrder.price,
        date: payload.message.deal.takeProfitOrder.updatedTime ?? 0,
        status: payload.message.deal.takeProfitOrder.status
      }
    })

    this._orderList = this._orderList.filter((item) => item.dealId !== payload.message.deal.id)
  }

  private _deleteDeal (value: IDealsMessage): void {
    this._dealList = this._dealList.filter((item) => item.id !== value.deal.id)
  }

  private _transformSocketOrder (value: IDealsMessage, target: 'takeProfitOrder' | 'stopLossOrder'): IOrderDTO {
    return {
      id: value.deal[target].id.toString(),
      clientOrderId: '',
      dealId: value.deal.id,
      pair: value.deal.pair,
      status: OrderStatus.NEW,
      tradeType: '',
      source: OrderSource.VIRTUAL,
      type: value.deal[target].type,
      side: value.deal[target].side,
      amount: value.deal[target].amount,
      executedAmount: value.deal[target].executedAmount,
      price: value.deal[target].price,
      stopPrice: value.deal[target].price,
      createdDate: value.deal[target].date,
      updatedDate: value.deal[target].date,
      completePercent: value.deal[target].completePercent,
      takeProfit: { isInclude: value.deal.takeProfitOrder.isInclude, percent: value.deal.takeProfitOrder.percent },
      stopLoss: { isInclude: value.deal.stopLossOrder.isInclude, percent: value.deal.stopLossOrder.percent }
    }
  }

}

export { OrderDealSyncManager }
