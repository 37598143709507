import { HttpServiceV1 } from '@data/common/services'

import { ExceptionService } from '@domain/common/services'
import { InternalCode, ValidationCode } from '@domain/common/enums'

import { BinanceErrorCode } from '@data/stocks/binance/enums'
import { BinanceErrorMessage } from '@data/stocks/binance/enums/error-message'

import { FilterCode } from '@domain/common/enums/internal-code'

import { LicenseErrorsHandler } from '@data/repository/license/decorator'

import {
  BinanceOrderType,
  transformSideFromBinance,
  transformTypeFromBinance,
  transformStatusFromBinance,
  transformSideToBinance,
  transformTypeToBinance
} from '@data/stocks/binance'

import { OrderSource } from '@domain/stocks/order'

import { OrderRepository } from '../../_'
import { BinanceFilterErrorMessage } from '../enums'

import type {
  IBinanceOrder,
  ICreateOrderPayload,
  IUpdateOrderPayload
} from '../interfaces'

import type {
  IGetOpenOrderListPort,
  ICancelOrderPort,
  ICreateOrderPort,
  IOrderDTO,
  IOrderRepository,
  IUpdateOrderPort
} from '@domain/stocks/order'

import type { IPairDTO } from '@domain/stocks/pair'

import type { IHttpError } from '@data/common/interfaces'
import type { IBinanceHttpError } from '@data/stocks/binance'

class BinanceOrderRepository extends OrderRepository implements IOrderRepository {

  public override async getOpenOrderList (port: IGetOpenOrderListPort): Promise<IOrderDTO[]> {
    return HttpServiceV1.get<IBinanceOrder[] | null>(`/${this.provider}/active-orders`)
      .then((response) => {
        if (response === null) return []

        return this._transformOrderList(response, port.list)
      })
      .catch((error: IHttpError<IBinanceHttpError>) => {
        throw ExceptionService.new({
          status: {
            code: BinanceOrderRepository._transformError(error.errors.code),
            message: error.errors.message
          }
        })
      })
  }

  public override async createOrder (port: ICreateOrderPort): Promise<boolean> {
    const payload: ICreateOrderPayload = {
      symbol: port.pair.ticker.symbol,
      side: transformSideToBinance(port.side),
      type: transformTypeToBinance(port.type),
      quantity: port.amount,
      price: port.price,
      timeInForce: 'GTC'
    }

    this._transformOrderPayload(payload)

    return HttpServiceV1.post(`/${this.provider}/create`, { body: payload })
      .then(() => true)
      .catch((error: IHttpError<IBinanceHttpError>) => {
        throw ExceptionService.new({
          status: {
            code: BinanceOrderRepository._transformError(error.errors.code, error.errors.message),
            message: error.errors.message
          }
        })
      })
  }

  public override async updateOrder (port: IUpdateOrderPort): Promise<boolean> {
    const payload: IUpdateOrderPayload['orders'] = port.orders.map((item) => {
      return {
        orderId: Number(item.orderId),
        symbol: item.pair.ticker.symbol,
        side: transformSideToBinance(item.side),
        type: transformTypeToBinance(item.type),
        quantity: item.amount,
        price: item.price,
        timeInForce: 'GTC'
      }
    })

    this._transformOrderPayload(payload)

    return HttpServiceV1.post(`${this.provider}/update`, { body: { orders: payload }})
      .then(() => true)
      .catch((error: IHttpError<IBinanceHttpError>) => {
        throw ExceptionService.new({
          status: {
            code: BinanceOrderRepository._transformError(error.errors.code, error.errors.message),
            message: error.errors.message
          }
        })
      })
  }

  public async cancelOrder (port: ICancelOrderPort): Promise<boolean> {
    return HttpServiceV1.post(`/${this.provider}/cancel`, {
      body: {
        orderId: Number(port.id),
        symbol: port.pair.ticker.symbol
      }
    })
      .then(() => true)
  }

  private _transformOrderList (payload: IBinanceOrder[], list: IPairDTO[]): IOrderDTO[] {
    const orders: IOrderDTO[] = []

    payload.forEach((item) => {
      const pair = list.find((_pair) => _pair.ticker.symbol === item.symbol)

      if (pair) {
        orders.push({
          id: item.id.toString(),
          clientOrderId: item.clientOrderId,
          dealId: item.deal.id,
          pair: pair,
          createdDate: item.updatedAt * 1000,
          updatedDate: item.updatedAt * 1000,
          side: transformSideFromBinance(item.side),
          source: OrderSource.ORIGIN,
          type: transformTypeFromBinance(item.type),
          status: transformStatusFromBinance(item.status),
          amount: item.qty,
          executedAmount: item.execQty,
          price: item.price,
          stopPrice: item.stopPrice,
          tradeType: 'SPOT',
          completePercent: (Number(item.execQty) / Number(item.qty) * 100).toString(),
          takeProfit: { isInclude: item.deal.includeTp, percent: item.deal.tp === '' ? '1.00' : item.deal.tp },
          stopLoss: { isInclude: item.deal.includeSl, percent: item.deal.sl === '' ? '3.00' : item.deal.sl }
        })
      }
    })

    return orders
  }

  private _transformOrderPayload (payload: ICreateOrderPayload | IUpdateOrderPayload['orders']): void {
    if (Array.isArray(payload)) {
      payload.forEach((item) => {
        this._deleteFromOrderPayload(item)
      })

      return
    }

    this._deleteFromOrderPayload(payload)
  }

  private _deleteFromOrderPayload (payload: ICreateOrderPayload): void {
    if (payload.type === BinanceOrderType.MARKET) {
      delete payload.price
      delete payload.timeInForce
    }
  }

  @LicenseErrorsHandler()
  private static _transformError (code: string, message?: string): InternalCode | ValidationCode | FilterCode {
    switch (code) {
      case BinanceErrorCode.ERROR_API_LIMIT: return InternalCode.LIMIT_ERROR
      case BinanceErrorCode.INVALID_API_KEY: return InternalCode.INVALID_API_KEY
      case BinanceErrorCode.INVALID_API_KEY_MESSAGE: return InternalCode.INVALID_API_KEY
      case BinanceErrorCode.INVALID_SYMBOL: return InternalCode.PROPERTY_IS_INVALID
      case BinanceErrorCode.API_KEY_NOT_FOUND: return InternalCode.API_KEY_NOT_FOUND
      case BinanceErrorCode.ACTIVE_ORDER_EXISTS: return InternalCode.MULTIORDER
      case BinanceErrorCode.ORDER_UPDATE: return InternalCode.PROPERTY_NOT_FOUND
      case BinanceErrorCode.INVALID_DEAL_MIN_NOTIONAL: return FilterCode.DEAL_MIN_NOTIONAL
      case BinanceErrorCode.INVALID_ORDER_PARAMS: return this._transformBinanceError(message ?? '')
      case BinanceErrorCode.FILTER_ORDER: return this._transformBinanceFilterError(message ?? '')
      case BinanceErrorCode.FILTER_ORDER_MESSAGE: return this._transformBinanceFilterError(message ?? '')
      default: return InternalCode.SERVER_ERROR
    }
  }

  private static _transformBinanceError (message: string): InternalCode | ValidationCode | FilterCode {
    switch (message) {
      case BinanceErrorMessage.INVALID_STOP_PRICE: return InternalCode.INVALID_STOP_PRICE
      case BinanceErrorMessage.INVALID_BALANCE: return ValidationCode.INVALID_BALANCE
      default: return InternalCode.SERVER_ERROR
    }
  }

  private static _transformBinanceFilterError (message: string): InternalCode | ValidationCode | FilterCode {
    switch (message) {
      case BinanceFilterErrorMessage.MAX_NUM_ALGO_ORDERS: return FilterCode.MAX_NUM_ALGO_ORDERS
      case BinanceFilterErrorMessage.PRICE_FILTER: return FilterCode.PRICE_FILTER
      case BinanceFilterErrorMessage.PERCENT_PRICE: return FilterCode.PERCENT_PRICE
      case BinanceFilterErrorMessage.PERCENT_PRICE_BY_SIDE: return FilterCode.PERCENT_PRICE_BY_SIDE
      case BinanceFilterErrorMessage.LOT_SIZE: return FilterCode.LOT_SIZE
      case BinanceFilterErrorMessage.MIN_NOTIONAL: return FilterCode.MIN_NOTIONAL
      case BinanceFilterErrorMessage.ICEBERG_PARTS: return FilterCode.ICEBERG_PARTS
      case BinanceFilterErrorMessage.MARKET_LOT_SIZE: return FilterCode.MARKET_LOT_SIZE
      case BinanceFilterErrorMessage.MAX_POSITION: return FilterCode.MAX_POSITION
      case BinanceFilterErrorMessage.MAX_NUM_ORDERS: return FilterCode.MAX_NUM_ORDERS
      case BinanceFilterErrorMessage.MAX_NUM_ICEBERG_ORDERS: return FilterCode.MAX_NUM_ICEBERG_ORDERS
      case BinanceFilterErrorMessage.TRAILING_DELTA: return FilterCode.TRAILING_DELTA
      case BinanceFilterErrorMessage.EXCHANGE_MAX_NUM_ORDERS: return FilterCode.EXCHANGE_MAX_NUM_ORDERS
      case BinanceFilterErrorMessage.EXCHANGE_MAX_NUM_ALGO_ORDERS: return FilterCode.EXCHANGE_MAX_NUM_ALGO_ORDERS

      case BinanceFilterErrorMessage.PRICE_FILTER_MESSAGE: return FilterCode.PRICE_FILTER
      case BinanceFilterErrorMessage.MARKET_LOT_SIZE_MESSAGE: return FilterCode.MARKET_LOT_SIZE

      default: return InternalCode.SERVER_ERROR
    }
  }

}

export { BinanceOrderRepository }
