import { transformSideFromBinance, transformTypeFromBinance, transformStatusFromBinance } from '@data/stocks/binance'

import { OrderSource } from '@domain/stocks/order'

import { OrderSocket } from '../../_'

import type { IBinanceOrderSocketResponse } from '../interfaces'

import type { Stocks } from '@domain/common/enums'
import type { IOrderDTO } from '@domain/stocks/order'
import type { IPairDTO } from '@domain/stocks/pair'

class BinanceOrderSocket extends OrderSocket<IBinanceOrderSocketResponse> {

  constructor (stock: Stocks, list: IPairDTO[]) {
    super('orders_', stock, list)
  }

  public override onMessage (callback: (message: IOrderDTO) => void): void {
    this.socket?.onMessage((message) => {
      const takeProfit = {
        isInclude: message.OrderInfo.deal?.includeTp ?? false,
        percent: message.OrderInfo.deal?.tp ?? '1.00'
      }

      const stopLoss = {
        isInclude: message.OrderInfo.deal?.includeSl ?? false,
        percent: message.OrderInfo.deal?.sl ?? '3.00'
      }

      const pair = this.list.find((item) => item.ticker.symbol === message.OrderInfo.order.symbol)

      if (pair) {
        callback({
          id: message.OrderInfo.order.id.toString(),
          clientOrderId: message.OrderInfo.order.clientOrderId,
          dealId: 0,
          pair: pair,
          side: transformSideFromBinance(message.OrderInfo.order.side),
          type: transformTypeFromBinance(message.OrderInfo.order.type),
          status: transformStatusFromBinance(message.OrderInfo.order.status),
          amount: message.OrderInfo.order.qty,
          executedAmount: message.OrderInfo.order.execQty,
          price: message.OrderInfo.order.price,
          source: OrderSource.ORIGIN,
          stopPrice: message.OrderInfo.order.stopPrice,
          createdDate: message.OrderInfo.order.createdAt * 1000,
          updatedDate: message.OrderInfo.order.updatedAt * 1000,
          completePercent: (+message.OrderInfo.order.execQty / +message.OrderInfo.order.qty * 100).toString(),
          tradeType: 'SPOT',
          takeProfit: takeProfit,
          stopLoss: stopLoss
        })
      }
    })
  }

}

export { BinanceOrderSocket }
