import { HttpServiceV2 } from '@data/common/services'
import { LicenseStatus } from '@domain/license'
import { ExceptionService } from '@domain/common/services'

import { HttpCode } from '@data/common/enums'

import { InternalCode } from '@domain/common/enums'

import type {
  ILicenseHttpError,
  IBuyLicenseResponse,
  ILicenseListResponse,
  ILicenseSettings,
  IUserLicenseListResponse,
  IUnpaidLicenseResponse,
  IGiftLicensePayload
} from '../interfaces'

import type {
  ILicensePortWithId,
  IBuyLicensePort,
  IGiftLicensePort,
  ILicenseDTO,
  IPaymentLicenseDTO,
  IUnpaidLicenseDTO,
  ILicenseRepository,
  ILicenseSettingsDTO,
  IUserLicenseDTO
} from '@domain/license'
import type { IHttpError } from '@data/common/interfaces'

class LicenseRepository implements ILicenseRepository {

  public async getLicenseList (): Promise<ILicenseDTO[]> {
    return HttpServiceV2.get<ILicenseListResponse[]>('license/relations')
      .then((response) => {
        return response.map((item) => {
          return {
            id: item.licenseId,
            name: item.name,
            settings: this._transformSettings(item.settings),
            periods: item.relations.map((relation) => {
              return {
                id: relation.relationId,
                name: relation.name,
                dayCount: relation.dayCount,
                pauseCount: relation.pauseCount,
                price: Number(relation.price),
                discountPrice: Number(relation.salePrice),
                discountPercent: relation.salePercent,
                discountStartDate: relation.saleDateStart * 1000,
                discountEndDate: relation.saleDateEnd * 1000
              }
            })
          }
        })
      })
  }

  public async getUserLicenseList (): Promise<IUserLicenseDTO[]> {
    return this._getUserLicenseList()
  }

  public async getCurrentUserLicense (): Promise<IUserLicenseDTO | null> {
    return this._getUserLicenseList()
      .then((response) => {
        return response.find((item) => item.status === LicenseStatus.ACTIVE) ?? null
      })
  }

  public async getUnpaidLicense (): Promise<IUnpaidLicenseDTO[]> {
    return HttpServiceV2.get<IUnpaidLicenseResponse[] | null>('/license/invoices')
      .then((response) => {
        if (response === null) return []

        return response.map((item) => {
          return {
            name: item.name,
            dayCount: item.period,
            price: Number(item.amount),
            endDate: item.expiryDate,
            link: item.link
          }
        })
      })
  }

  public async buyLicense (port: IBuyLicensePort): Promise<IPaymentLicenseDTO> {
    return HttpServiceV2.post<IBuyLicenseResponse>('/license/buy', { body: {
      relationId: port.id,
      buyCount: port.count
    }})
      .catch((error: IHttpError<ILicenseHttpError>) => {
        throw ExceptionService.new({
          status: {
            code: +error.errors.code,
            message: error.errors.message
          }
        })
      })
  }

  public async giftLicense (port: IGiftLicensePort): Promise<IPaymentLicenseDTO> {
    const payload: IGiftLicensePayload = { relationId: port.id, buyCount: port.count }

    if (port.userId !== undefined) {
      return this._giftLicense('/license/gift-by-id', { ...payload, userId: port.userId })
    }

    return this._giftLicense('/license/gift', { ...payload, email: port.email })
  }

  public async activateLicense (port: ILicensePortWithId): Promise<void> {
    await HttpServiceV2.post('license/activate', { body: port })
  }

  public async startLicense (port: ILicensePortWithId): Promise<void> {
    await HttpServiceV2.post('license/unpause', { body: port })
  }

  public async stopLicense (port: ILicensePortWithId): Promise<void> {
    await HttpServiceV2.post('license/pause', { body: port })
  }

  private async _getUserLicenseList (): Promise<IUserLicenseDTO[]> {
    return HttpServiceV2.get<IUserLicenseListResponse[] | null>('license/subscriptions')
      .then((response) => {
        if (response === null) return []

        return response.map((item) => {
          return {
            id: item.id,
            name: item.name,
            status: item.status,
            pauseCount: item.pauseLeft,
            dateStart: item.dateStart === null ? null : item.dateStart * 1000,
            dateEnd: item.dateEnd === null ? null : item.dateEnd * 1000,
            settings: this._transformSettings(item.settings)
          }
        })
      })
  }

  private async _giftLicense (url: string, payload: IGiftLicensePayload): Promise<IPaymentLicenseDTO> {
    return HttpServiceV2.post<IBuyLicenseResponse>(url, { body: payload })
      .catch((error: IHttpError<ILicenseHttpError>) => {
        const data = error.errors.message === 'user_not_found' ? { logical: InternalCode.PROPERTY_NOT_FOUND } : {}

        throw ExceptionService.new({
          status: {
            code: HttpCode.BAD_REQUEST,
            message: `${this.constructor.name} http error`
          }, data
        })
      })
  }

  private _transformSettings (value: ILicenseSettings): ILicenseSettingsDTO {
    return {
      isIncludeIceberg: value.order.icebergOn,
      isIncludeMultiOrders: value.order.multiOrdersOn,
      orderCount: value.order.count,
      maxVolume: value.order.maxAmountVolume,
      maxVolumePerOrder: value.order.maxVolume
    }
  }

}

export { LicenseRepository }
