import { CentrifugeService, STOCK_CONNECTION } from '@data/common/services'

import {
  BinanceOrderSide,
  BinanceOrderStatus,
  BinanceOrderType,
  transformSideFromBinance,
  transformStatusFromBinance,
  transformTypeFromBinance
} from '@data/stocks/binance'

import type { IPairDTO } from '@domain/stocks/pair'

import type { IDealsMessage, IDealsResponseSocket } from './interfaces'

import type { Stocks } from '@domain/common/enums'

class DealsSocket {

  protected list: IPairDTO[]

  private _socket?: CentrifugeService<IDealsResponseSocket>

  constructor (list: IPairDTO[]) {
    this.list = list
  }

  public subscribe (stock: Stocks, id: string): void {
    this._socket = new CentrifugeService(`deals_${id}`, STOCK_CONNECTION[stock])
  }

  public unsubscribe (): void {
    this._socket?.close()
  }

  public onMessage (callback: (value: IDealsMessage) => void): void {
    this._socket?.onMessage((data) => {
      const deal = data.DealInfo.deal
      const masterOrder = data.DealInfo.masterOrder
      const tpOrder = data.DealInfo.tpOrder
      const slOrder = data.DealInfo.slOrder

      const takeProfitSide = tpOrder?.side ?? deal.side
      const stopLossSide = slOrder?.side ?? deal.side
      const takeProfitAmount = tpOrder?.qty ?? deal.qty
      const stopLossAmount = slOrder?.qty ?? deal.qty

      const dealPrice = Number(deal.price)
      const takeProfitPriceDifference = dealPrice * Number(deal.tp) / 100
      const stopLossPriceDifference = dealPrice * Number(deal.sl) / 100

      const takeProfitOrder = {
        id: tpOrder?.id ?? 0,
        isInclude: deal.includeTp,
        side: transformSideFromBinance(takeProfitSide),
        type: transformTypeFromBinance(tpOrder?.orderType ?? BinanceOrderType.LIMIT),
        status: transformStatusFromBinance(tpOrder?.status ?? BinanceOrderStatus.CANCELED),
        amount: takeProfitAmount,
        executedAmount: tpOrder?.execQty ?? '',
        price: tpOrder === null
          ? deal.side === BinanceOrderSide.SELL
            ? (dealPrice + takeProfitPriceDifference).toString()
            : (dealPrice - takeProfitPriceDifference).toString()
          : tpOrder.price,
        percent: deal.tp,
        completePercent: (tpOrder?.execQty ?? 0 / Number(takeProfitAmount) * 100).toString(),
        date: tpOrder?.createdAt === undefined ? 0 : tpOrder.createdAt * 1000,
        updatedTime: tpOrder?.updatedAt === undefined ? 0 : tpOrder.updatedAt * 1000
      }

      const stopLossOrder = {
        id: slOrder?.id ?? 0,
        isInclude: deal.includeSl,
        side: transformSideFromBinance(stopLossSide),
        type: transformTypeFromBinance(slOrder?.orderType ?? BinanceOrderType.MARKET),
        status: transformStatusFromBinance(slOrder?.status ?? BinanceOrderStatus.CANCELED),
        amount: stopLossAmount,
        executedAmount: slOrder?.execQty ?? '',
        price: slOrder === null
          ? deal.side === BinanceOrderSide.SELL
            ? (dealPrice - stopLossPriceDifference).toString()
            : (dealPrice + stopLossPriceDifference).toString()
          : slOrder.price,
        percent: deal.sl,
        completePercent: (slOrder?.execQty ?? 0 / Number(stopLossAmount) * 100).toString(),
        date: slOrder?.createdAt === undefined ? 0 : slOrder.createdAt * 1000,
        updatedTime: slOrder?.updatedAt === undefined ? 0 : slOrder.updatedAt * 1000
      }

      const pair = this.list.find((_pair) => _pair.ticker.symbol === deal.symbol)

      if (pair) {
        callback({
          event: data.Event,
          deal: {
            id: deal.id,
            pair: pair,
            side: transformSideFromBinance(deal.side),
            status: deal.status,
            amount: deal.qty,
            price: deal.price,
            openUsdtPrice: deal.usdtOpenPrice,
            profitUSDT: deal.usdtProfit,
            unionVolume: deal.unionVolume,
            unionCommission: deal.unionCommission,
            createdAt: deal.createdAt * 1000,
            updatedAt: deal.updatedAt * 1000,
            masterOrder: {
              id: masterOrder.id,
              date: masterOrder.createdAt * 1000,
              type: transformTypeFromBinance(masterOrder.type),
              side: transformSideFromBinance(masterOrder.side),
              status: transformStatusFromBinance(masterOrder.status),
              price: masterOrder.price,
              amount: masterOrder.qty,
              executedAmount: masterOrder.execQty,
              updatedTime: masterOrder.updatedAt * 1000,
              commission: {
                percent: masterOrder.comPercent,
                asset: masterOrder.comTicker,
                amount: masterOrder.commission
              }
            },
            takeProfitOrder: takeProfitOrder,
            stopLossOrder: stopLossOrder
          }
        })
      }
    })
  }

}

export { DealsSocket }
